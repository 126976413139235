.bg-common {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.white-bg {
  background-image: url("./img/white-bg.webp");
}

.white-bg-2 {
  background-image: url("./img/white-bg-2.webp");
}

.black-bg {
  background-image: url("./img/black-bg.webp");
}

.orange-bg {
  background-image: url("./img/orange-bg.webp");
}

.bg-img-5 {
  background-image: url("./img/bg-5.webp");
}

.bg-img-1 {
  background-image: url("./img/bg-1.webp");
}